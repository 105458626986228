import React, { useEffect, useRef, useState } from "react";
import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";
import SignatureCanvas from "react-signature-canvas";
import ChangeBoothForSigPad from "views/Modal/ChangeBoothForSigPad";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { toast } from "react-toastify";
import Unauthorized from "views/Pages/Unauthorized";
import btc_qr from "../../assets/img/btc_qr.png";
import eth_qr from "../../assets/img/eth_qr.png";
// reactstrap components

function RenderSignatures(props) {
  const sigCanvas = useRef(null);
  const [changeInterval, setChangeInterval] = useState(false);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isThank, setIsThank] = useState(false);
  const [exist, setExist] = useState(false);
  const [id, setId] = useState("");
  const [unauthorized, setUnauthorized] = useState(false);
  const [type, setType] = useState("");
  const [autoSave, setAutosave] = useState(false);
  const [cryptoExist, setCryptoExist] = useState(false);
  const [showQR, setShowQR] = useState({});

  //________disable/enable signature enable_________________
  if (sigCanvas.current) {
    if (props.enabled) {
      sigCanvas.current.on();
    } else {
      sigCanvas.current.off();
    }
  }

  useEffect(() => {
    let inter;
    try {
      inter = setInterval(async () => {
        PostApiWithAuthorizationAction("common/get-signature-info", {
          booth: props.booth,
          id: id,
          exist: exist,
        })
          .then((res) => {
            if (!exist) {
              setId(res.data.id);
              setType(res.data.type);
              setExist(res.data.exist);
            } else {
              if (res.status === 400) {
                setId("");
                setType("");
                setExist(false);
              }
            }
          })
          .catch((err) => {
            if (err.status === 403) {
              setUnauthorized(true);
            } else if (err.status === 440) {
              toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
              props.history.push("/auth/login");
            }
          });
      }, 5000);
    } catch (e) {
      console.log(e);
    }
    return () => {
      clearInterval(inter);
    };
  }, [changeInterval]);

  //for Count down
  useEffect(() => {
    count > 0 && setTimeout(() => setCount(count - 1), 1000);
  }, [count]);
  const clear = (e) => sigCanvas.current.clear();

  const save = (e) => {
    //check signature canvas empty or not
    if (!sigCanvas.current.isEmpty()) {
      //If signature canvas exist
      if (exist) {
        let body = {
          id: id,
          type: type,
          station: localStorage.getItem("lastUsedPrinter"),
          signature: sigCanvas.current
            .getTrimmedCanvas()
            .toDataURL("image/png"),
        };
        setLoading(true);
        PostApiWithAuthorizationAction("common/save-signature", body)
          .then((res) => {
            setLoading(false);
            setExist(false);
            setId("");
            setType("");
            setChangeInterval(!changeInterval);

            if (res.cryptoExist.btcExist || res.cryptoExist.ethExist) {
              setCryptoExist(true);
              setShowQR(res.cryptoExist);
              setTimeout(() => {
                setCryptoExist(false);
                setShowQR({});
              }, 1000 * 60 * 10);
            }

            setIsThank(true);

            setAutosave(false);
            //on countdown
            setCount(10);

            setTimeout(() => {
              setIsThank(false);
            }, 10000);
            //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
          })
          .catch((err) => {
            setLoading(false);
            if (err.status === 403) {
              setUnauthorized(true);
            } else if (err.status === 440) {
              toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
              props.history.push("/auth/login");
            } else {
              toast.error("Something went wrong! /n Please clear and retry", {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          });
      }
    } else {
      toast.error("Signature Canvas is Empty!Please add Signature", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Container className="" fluid>
        <Card className="shadow back-grey h-300">
          <CardHeader className="back-grey py-2">
            <div className="text-center">
              <h3 className="mb-0">Signature Pad ("{props.booth}"")</h3>
              {id && type && (
                <h5 className="mt-0">
                  Ticket Id: {id}, Ticket Type: {type}
                </h5>
              )}
            </div>
          </CardHeader>
          <CardBody className="align-items-center d-flex justify-content-center">
            {exist ? (
              <div className="text-center w-100">
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  backgroundColor="white"
                  canvasProps={{
                    width: 270,
                    height: 130,
                    className: "sigPad",
                  }}
                  onBegin={(e) => {
                    if (props.enabled) {
                      if (!autoSave) {
                        setAutosave(true);
                        setTimeout(() => {
                          var pagebutton = document.getElementById("saveBtn");
                          if (pagebutton) {
                            pagebutton.click();
                          }
                        }, 3000);
                      }
                    }
                  }}
                />
                <br />
                {props.enabled && (
                  <>
                    {loading ? (
                      <Button color="primary" disabled>
                        Saving <i className="fas fa-spinner fa-spin"></i>
                      </Button>
                    ) : (
                      <Button color="primary" id="saveBtn" onClick={save}>
                        Save
                      </Button>
                    )}
                    <Button
                      color="secondary"
                      onClick={clear}
                      disabled={loading}
                    >
                      Clear
                    </Button>
                  </>
                )}
              </div>
            ) : cryptoExist ? (
              <div className="text-center w-100">
                <h4 className="mb-2">Crypto wallet QR Codes and Address</h4>
                {showQR.btcExist && (
                  <>
                    <img src={btc_qr} width="60" />
                    <h5 className="mb-3">
                      BTC wallet Address: 5rfhgcftr5fhfgd5rffhggh
                    </h5>
                  </>
                )}
                {showQR.ethExist && (
                  <>
                    <img src={eth_qr} width="60" />
                    <h5>ETH wallet Address: 5rfhgcftr5fhfgd5rffhggh</h5>
                  </>
                )}

                <Button
                  className="mt-4"
                  color="primary"
                  onClick={(e) => {
                    setExist(false);
                    setId("");
                    setType("");
                    setIsThank(false);
                    setCryptoExist(false);
                    setShowQR({});
                  }}
                >
                  Close
                </Button>
              </div>
            ) : isThank ? (
              <div className="text-center">
                <h1>Thank you!</h1>
                <p>Your submission has been received.</p>
                <span id="timer">
                  This page will redirect in {count} seconds.
                </span>
              </div>
            ) : (
              <div className="text-center">
                <h2>Please Wait!</h2>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default RenderSignatures;
